import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';

const Project = ({
    data: {
        projectsJson: {
            name,
            client,
            year,
            description,
            tags,
        },
        allFile
    },
}) => (
    <Layout>
        <header className="text-center">
            <h1 className="project-title">
                {name}
            </h1>
            <p className="project-sub-title">{client} - {year}</p>
            <p className="project-description">{description}</p>
        </header>
        <main>
            {allFile && <ul className="unstyled-list">
                {
                    allFile.edges.map(({ node: { id, publicURL, name }}) => (
                        <li key={id} className="img-holder project-image-item">
                            <img src={publicURL} alt={name} />
                        </li>
                        )
                    )
                }
            </ul>}
            {!allFile && <p className="no-images-available">Project still in progress. No images available right now.</p>}
            <ul className="inline-list tag-list text-center">
                {tags.map(tag => <li key={tag}>{tag}</li>)}
            </ul>
        </main>
    </Layout>
)

export default Project;

export const pageQuery = graphql`
  query($id: String!, $sourcesUrl: String!) {
    projectsJson(id: { eq: $id }) {
        name
        cover
        client
        year
        description
        tags
        position
    }
    allFile(
        sort: {
            order: ASC,
            fields: [name]
        }
        filter: {
            absolutePath: {
                regex: $sourcesUrl
            }
        }) {
        edges {
          node {
            id
            publicURL
            name
          }
        }
      }
  }
`